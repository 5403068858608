import { createSlice, PayloadAction } from "redux-starter-kit";

export type LineType = {
  en: string;
  boy: string;
  adet: string;
  pvcBoy1: string;
  pvcBoy2: string;
  pvcEn1: string;
  pvcEn2: string;
  kanal: string;
};

export type LinesState = LineType[];

const initialState: LinesState = [];

const lines = createSlice({
  slice: "lines",
  initialState,
  reducers: {
    addLine(state, action: PayloadAction<{ index: number }>) {
      state.splice(action.payload.index, 0, {
        boy: "",
        en: "",
        adet: "",
        pvcBoy1: "",
        pvcBoy2: "",
        pvcEn1: "",
        pvcEn2: "",
        kanal: ""
      });
    },
    removeLine(state, action: PayloadAction<{ index: number }>) {
      state.splice(action.payload.index, 1);
    },
    setLineField(
      state,
      action: PayloadAction<{
        index: number;
        key: keyof LineType;
        value: string;
      }>
    ) {
      const { index, key, value } = action.payload;
      state[index][key] = value;
    }
  }
});

export const { addLine, removeLine, setLineField } = lines.actions;

export default lines.reducer;
