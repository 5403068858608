import React, { useState } from "react";
import styled from "styled-components/macro";
import { Flex } from "@zedflow-mono/styled-system";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

import { SendIcon, WarningIcon, CheckCircleIcon } from "../../utils/Icons";
import Paper from "../../components/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import { useStore } from "react-redux";
import { RootState } from "../../store";
import { postData } from "../../api";

const Footer = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [payload, setPayload] = useState(null);
  const store = useStore<RootState>();

  function handleClickOpen() {
    const state = store.getState();

    let err = "";
    if (state.headData.musteriAdi === "") {
      err = 'Lütfen "Müşteri Adı" alanını doldurunuz.';
    } else if (state.headData.musteriSehir === "") {
      err = 'Lütfen "Müşteri İl/İlçe" alanını doldurunuz.';
    }
    if (err !== "") {
      setSnackbarText(err);
      setSnackbarOpen(true);
    } else {
      // @ts-ignore
      setPayload(state);
      setConfirmOpen(true);
    }
  }

  function handleClose() {
    setConfirmOpen(false);
  }

  function handleCloseSnackbar() {
    setSnackbarOpen(false);
  }

  function handleSend() {
    setLoading(true);
    //console.log(payload);

    if (payload) {
      postData(payload).then((res: any) => {
        //console.log(res);
        setConfirmOpen(false);
        setSuccessOpen(true);
      });
    }
    // setTimeout(() => {
    //   setSuccessOpen(false);
    //   setLoading(false);
    // }, 5000);
  }

  return (
    <Wrapper>
      <Flex flexDirection="row">
        <Flex width="70%" />
        <Flex flexWrap="wrap">
          <StyledButton onClick={handleClickOpen}>
            <SendIcon size={30} /> SİPARİŞİ GÖNDER
          </StyledButton>
        </Flex>
      </Flex>
      <StyledDialog
        open={confirmOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          Siparişi Göndermek istediğinize emin misiniz?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            'Evet' butonuna tıklamanız halinde siparişiniz firmamıza yönlenecek
            ve ekranınız sıfırlanacaktır.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <div className="siparis-gonderiliyor">
              <span>Siparişiniz Gönderiliyor...</span>
              <br />
              <LinearProgress value={50} />
            </div>
          ) : (
            <>
              <Button onClick={handleClose} className="btn-hayir">
                Hayır
              </Button>
              <Button className="btn-evet" autoFocus onClick={handleSend}>
                <SendIcon size={20} />
                EVET, Siparişi Gönder
              </Button>
            </>
          )}
        </DialogActions>
      </StyledDialog>

      <StyledDialog
        open={successOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className="success-title">
          <CheckCircleIcon size={42} />
          Siparişiniz Gönderildi!
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="success-detail">
            Siparişiniz ile ilgili süreçler için lütfen bizi arayınız.
          </DialogContentText>
        </DialogContent>
      </StyledDialog>

      <StyledSnackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={snackbarOpen}
        autoHideDuration={300000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={
          <>
            <WarningIcon size={42} />
            <span className="hata-mesaji">{snackbarText}</span>
          </>
        }
      />
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled(Paper)`
  #root & {
    border: 1px solid #e0e0e0;
    border-width: 1px 0;
  }
`;

const StyledSnackbar = styled(Snackbar)`
  #root & {
    .MuiSnackbarContent-root {
      background-color: #c33e38;
      font-size: 20px;
      width: 880px;
    }
    .hata-mesaji {
      margin-left: 15px;
    }
  }
`;

const StyledButton = styled(Button)`
  #root & {
    background: ${p => p.theme.colors.blue};
    color: #fff;
    .MuiButton-label {
      font-size: 20px;
      padding: 0px 20px 0 15px;
      font-weight: bold;
      svg {
        margin-right: 5px;
      }
    }
  }
`;

const StyledDialog = styled(Dialog)`
  &&& {
    .success-title {
      margin-top: 30px;
      h2 {
        color: green;
        font-size: 42px;
        svg {
          margin-right: 10px;
        }
      }
    }

    .success-detail {
      text-align: center;
      margin-bottom: 40px;
    }

    .btn-evet {
      background-color: ${p => p.theme.colors.blue};
      font-weight: bolder;
      color: #fff;
      padding: 5px 10px;
      svg {
        margin-right: 5px;
      }
    }
    .btn-hayir {
      color: darkred;
    }

    .siparis-gonderiliyor {
      margin-bottom: 20px;
      span {
        display: block;
        font-size: 18px;
      }
    }
  }
`;

StyledSnackbar.displayName = "StyledSnackbar";
StyledButton.displayName = "StyledButton";
Footer.displayName = "Footer";
StyledDialog.displayName = "StyledDialog";
