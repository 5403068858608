import React from "react";
import styled from "styled-components/macro";

import { Flex } from "@zedflow-mono/styled-system";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { WarningIcon } from "../../utils/Icons";
import { MenuIcon } from "../../utils/Icons";

const StyledWarningIcon = styled(WarningIcon)`
  margin-right: 10px;
  margin-top: -5px;
`;

const StyledMenuIcon = styled(MenuIcon)`
  margin-right: 10px;
  margin-top: -2px;
`;

export default () => {
  return (
    <Wrapper flexDirection="row">
      <div className="kenarbandi-wrapper">
        <h3>
          <StyledMenuIcon size={24} />
          Kenar bandı Çeşitleri
        </h3>
        <Table className="table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>KOD</TableCell>
              <TableCell>Kenar Bandı</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>A</TableCell>
              <TableCell>0.4 mm PVC</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B</TableCell>
              <TableCell>1 mm PVC HIGLOSS</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>C</TableCell>
              <TableCell>1 mm PVC AKRILIK</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>D</TableCell>
              <TableCell>1 mm PVC AKRILIK - Çift Renk</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div className="uyarilar">
        <h3>
          <StyledWarningIcon size={24} />
          UYARILAR
        </h3>
        <ul>
          <li>
            Desenli panellerde desen yönleri "BOY" Bölümüne yazılan ölçüye göre
            kesilir.
          </li>
          <li>
            Eğer ölçülerinizde Arkalık Kanalı açılacak parça varsa KANAL
            bilgisini seçiniz.
          </li>
          <li>Ölçü Hatalarından firmamız sorumlu değildir.</li>
          <li>
            Üç (3) gün içinde alınmayan firelerden firmamız sorumlu değildir.
          </li>
          <li>
            Kenar bandlarını işaretlerken "BOY" veya "EN" bölümünde yazan
            ölçünün karşısına, bantlanacak kenar sayısı kadar seçim yapınız.
          </li>
        </ul>
      </div>
    </Wrapper>
  );
};

const colors = {
  darkred: "#000"
};

const Wrapper = styled(Flex)`
  margin-top: 40px;

  .kenarbandi-wrapper {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    width: 35%;
  }

  .uyarilar {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid ${colors.darkred};
    width: 65%;
    margin-left: 10px;
    ul {
      font-size: 14px;
      li {
        margin-bottom: 6px;
      }
    }
    h3 {
      border-color: ${colors.darkred};
      color: ${colors.darkred};
    }
  }

  h3 {
    box-sizing: border-box;
    margin: 0;
    display: block;
    width: 100%;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    height: 36px;
  }
`;
