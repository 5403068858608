import { createMuiTheme } from "@material-ui/core/styles";

const theme = {
  ...createMuiTheme(),
  colors: {
    blue: "#0090FA",
    red: "#FF4C54"
  }
};

export default theme;
