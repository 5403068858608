import React, { useCallback } from "react";
import LineHead from "./LinesHeader";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import Line from "./line/Line";
import { addLine, removeLine } from "./lines.slice";

const Lines = () => {
  const dispatch = useDispatch();
  const lines = useSelector((state: RootState) => state.lines);

  const onAdd = useCallback(
    (index: number) => {
      dispatch(addLine({ index }));
    },
    [dispatch]
  );

  const onDelete = useCallback(
    (index: number) => {
      dispatch(removeLine({ index }));
    },
    [dispatch]
  );

  if (lines.length === 0) {
    onAdd(0);
  }

  return (
    <div>
      <LineHead />
      {lines.map((line, index) => (
        <Line index={index} key={index} onAdd={onAdd} onDelete={onDelete} />
      ))}
    </div>
  );
};

export default Lines;

// @ts-ignore
Lines.whyDidYouRender = true;
Lines.displayName = "Lines";
