import React, {
  KeyboardEventHandler,
  ChangeEventHandler,
  EventHandler
} from "react";
import { Flex, BoxProps } from "@zedflow-mono/styled-system";
import MuiTextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { LineType, setLineField } from "../lines/lines.slice";

export type TextFieldProps = BoxProps & {
  name: string;
  helperText?: string;
  placeholder?: string;
  label?: string;
  className?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  onBlur?: EventHandler<any>;
  type: string;
  index?: number;
};

const TextField: React.FC<TextFieldProps> = props => {
  const dispatch = useDispatch();
  const val = useSelector((state: RootState) => {
    if (props.index) {
      return state.lines[props.index][props.name as keyof LineType];
    }
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (props.index) {
      dispatch(
        setLineField({
          index: props.index,
          key: e.target.name as keyof LineType,
          value: e.target.value
        })
      );
    }
  };

  const showError = false;
  const helperText = "";

  return (
    <Flex width={props.width} className={props.className}>
      <MuiTextField
        onChange={handleChange}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        name={props.name}
        value={val}
        error={showError}
        helperText={helperText}
        placeholder={props.placeholder}
        label={props.label}
        inputProps={{ name: props.name }}
        inputRef={props.inputRef}
        type={props.type}
      />
    </Flex>
  );
};

TextField.displayName = "FormikMaterialUITextField";

export default TextField;
