import React from "react";
import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";
import { DeleteSweep } from "styled-icons/material/DeleteSweep";

const Icon = styled(DeleteSweep).attrs({ size: 22 })`
  transform: scale(-1, 1);
  size: 22;
`;

const BtnSil = ({ ...rest }) => {
  return (
    <Button variant="contained" color="secondary" {...rest}>
      <Icon />
    </Button>
  );
};

export default styled<typeof Button>(BtnSil)`
  #root & {
    margin-left: 10px;
    opacity: 0.8;
    min-width: 0;
    display: block;
    background-color: ${props => props.theme.colors.red};
    height: 34px;
    padding: 5px 10px;
    margin-top: 5px;
  }
`;
