import React from "react";
import { Box, Heading } from "@zedflow-mono/styled-system";
import styled from "styled-components/macro";

import logo from "../../assets/logo.svg";
import Paper from "../../components/Paper";

const Header = () => {
  return (
    <Wrapper>
      <Paper>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <img src={logo} alt="" className="logo" />
          </Box>
          <Box>
            <Heading as="h1" className="title">
              Ebatlama ve Bantlama Sipariş Formu
            </Heading>
          </Box>
        </Box>
      </Paper>
    </Wrapper>
  );
};

Header.displayName = "Header";

export default Header;

const Wrapper = styled.div`
  margin-top: 90px;
  .logo {
    width: 250px;
  }

  .title {
    padding-top: 30px;
    font-size: 28px;
    font-weight: normal;
  }
`;
