import { configureStore } from "redux-starter-kit";

import headDataReducer, {
  HeadDataState
} from "./features/head-data/headData.slice";
import linesReducer, { LinesState } from "./features/lines/lines.slice";

const store = configureStore<RootState>({
  reducer: {
    headData: headDataReducer,
    lines: linesReducer
  }
});

export type RootState = {
  headData: HeadDataState;
  lines: LinesState;
};

export default store;
