import React from "react";
import styled from "styled-components/macro";
import StyledSelect from "../../form-components/Select";
import { Flex, BoxProps } from "@zedflow-mono/styled-system";

type Props = BoxProps & {
  yon: "boy" | "en";
  num: 1 | 2;
  name: string;
  selectRef: React.RefObject<HTMLSelectElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<any>;
  onBlur: React.EventHandler<any>;
  index: number;
};

const KenarBandi: React.FC<Props> = ({
  yon,
  name,
  selectRef,
  onKeyDown,
  onKeyUp,
  onChange,
  onBlur,
  index,
  ...styledProps
}) => {
  let bg = "#dbf1ff";
  if (yon === "en") {
    bg = "#e6f9cf";
  }

  return (
    <Wrapper bg={bg} {...styledProps}>
      <StyledSelect
        name={name}
        native
        inputRef={selectRef}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={onChange}
        inputProps={onChange}
        onBlur={onBlur}
        index={index}
      >
        <option value="yok" />
        <option value={1}>A</option>
        <option value={2}>B</option>
        <option value={3}>C</option>
        <option value={4}>D</option>
      </StyledSelect>
    </Wrapper>
  );
};

export default KenarBandi;

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// styled

const Wrapper = styled(Flex)<{ bg: string }>`
  padding: 0px 8px 8px;
  background: ${props => props.bg};
`;
