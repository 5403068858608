import React from "react";
import styled from "styled-components/macro";
import StyledSelect from "../../form-components/Select";
import { Flex, BoxProps } from "@zedflow-mono/styled-system";

type Props = BoxProps & {
  name: string;
  selectRef: React.RefObject<HTMLSelectElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<any>;
  onBlur: React.EventHandler<any>;
  index: number;
};

export default ({
  name,
  selectRef,
  onKeyDown,
  onKeyUp,
  onChange,
  onBlur,
  index,
  ...rest
}: Props) => {
  return (
    <Wrapper {...rest}>
      <StyledSelect
        name={name}
        native
        inputRef={selectRef}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={onChange}
        onBlur={onBlur}
        index={index}
      >
        <option value="yok" />
        <option value={1}>1.2</option>
        <option value={2}>0.5</option>
      </StyledSelect>
    </Wrapper>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// styled

const Wrapper = styled(Flex)`
  padding: 0 8px 8px;
  background: #ffebb7;
`;
