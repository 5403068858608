import React, { ChangeEventHandler, EventHandler } from "react";
import { Flex, BoxProps } from "@zedflow-mono/styled-system";
import MuiSelect, {
  SelectProps as MuiSelectProps
} from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { setLineField, LineType } from "../lines/lines.slice";
import { RootState } from "../../store";

export type SelectProps = BoxProps &
  MuiSelectProps & {
    name: string;
    helperText?: string;
    placeholder?: string;
    label?: string;
    className?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: EventHandler<any>;
    index: number;
  };

const Select: React.FC<SelectProps> = props => {
  const dispatch = useDispatch();
  const val = useSelector((state: RootState) => {
    if (props.index) {
      return state.lines[props.index][props.name as keyof LineType];
    }
  });

  const handleChange: ChangeEventHandler<any> = e => {
    if (props.index) {
      dispatch(
        setLineField({
          index: props.index,
          key: e.target.name as keyof LineType,
          value: e.target.value
        })
      );
    }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const { children, onChange, ...rest } = props;

  // const showError = meta.touched && !!meta.error;
  // const helperText = showError ? meta.error : props.helperText;
  const showError = false;

  return (
    <Flex width={props.width} className={props.className}>
      <MuiSelect
        {...rest}
        onChange={handleChange}
        onBlur={props.onBlur}
        name={props.name}
        value={val}
        error={showError}
        placeholder={props.placeholder}
        inputProps={{
          name: props.name
          // id: ""
        }}
        // helperText={helperText}
        // label={props.label}
      >
        {children}
      </MuiSelect>
    </Flex>
  );
};

Select.displayName = "FormikMaterialUISelect";

export default Select;
