import React, { useContext } from "react";

export type FormRefsContextFormLinesType = {
  boy: React.RefObject<HTMLInputElement>;
  en: React.RefObject<HTMLInputElement>;
  adet: React.RefObject<HTMLInputElement>;
  pvcBoy1: React.RefObject<HTMLSelectElement>;
  pvcBoy2: React.RefObject<HTMLSelectElement>;
  pvcEn1: React.RefObject<HTMLSelectElement>;
  pvcEn2: React.RefObject<HTMLSelectElement>;
  kanal: React.RefObject<HTMLSelectElement>;
  btnAdd: React.RefObject<HTMLButtonElement>;
};

export type FormRefsContextValueType = {
  refs: {
    head: {
      musteriAdi?: React.RefObject<HTMLInputElement>;
      musteriSehir?: React.RefObject<HTMLInputElement>;
      siparisAciklama?: React.RefObject<HTMLInputElement>;
      malzemeAdi?: React.RefObject<HTMLInputElement>;
    };
    lines: FormRefsContextFormLinesType[];
  };
};

export const formRefsContextDefaultValue: FormRefsContextValueType = {
  refs: { head: {}, lines: [] }
};

const FormRefsContext = React.createContext<FormRefsContextValueType>(
  formRefsContextDefaultValue
);

FormRefsContext.displayName = "FormsRefContext";

export const useFormRefsContext = () => {
  return useContext(FormRefsContext);
};
export default FormRefsContext;
