import React from "react";
import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";
import { ListPlus } from "styled-icons/boxicons-regular/ListPlus";

const BtnEkle = ({ ...rest }) => (
  <Button variant="contained" color="primary" {...rest}>
    <ListPlus size={22} />
  </Button>
);

export default styled<typeof Button>(BtnEkle)`
  #root & {
    margin-left: 10px;
    opacity: 1;
    min-width: 0;
    display: block;
    background-color: ${props => props.theme.colors.blue};
    height: 34px;
    padding: 5px 10px;
    margin-top: 5px;
  }
`;
