import Paper from "@material-ui/core/Paper";
import styled from "styled-components/macro";

const StyledPaper = styled(Paper)`
  padding: ${props => props.theme.spacing(3, 2)};
  margin: 10px auto;
  width: 880px;
`;

export default StyledPaper;
