import React from "react";
import styled from "styled-components/macro";
import { Flex, BoxProps } from "@zedflow-mono/styled-system";

type Props = BoxProps & {
  no: number;
};

export default (props: Props) => {
  const { no, ...styledProps } = props;
  return (
    <Wrapper {...styledProps}>
      <div>{props.no}</div>
    </Wrapper>
  );
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// styled

const Wrapper = styled(Flex)`
  #root & {
    background: #fbfbfb;
    div {
      text-align: center;
      width: 100%;
    }
  }
`;
