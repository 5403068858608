import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    }
  })
);

const AppBar = () => {
  const classes = useStyles({ fontSize: 19 });

  return (
    <MuiAppBar position="fixed">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Aksakal Ebatlama Sipariş
        </Typography>
        <Button href="http://www.aksakalgrup.com/" color="inherit">
          Siteye Geri Dön
        </Button>
      </Toolbar>
    </MuiAppBar>
  );
};

AppBar.displayName = "AppBar";

export default AppBar;
