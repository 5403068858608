import React, { EventHandler } from "react";
import styled from "styled-components/macro";
import TextField from "../form-components/TextField";
import { Flex } from "@zedflow-mono/styled-system";
import { useDispatch } from "react-redux";
import { setField } from "./headData.slice";

export default () => {
  const headDataRefs = {
    musteriAdi: React.createRef<HTMLInputElement>(),
    musteriSehir: React.createRef<HTMLInputElement>(),
    siparisAciklama: React.createRef<HTMLInputElement>(),
    malzemeAdi: React.createRef<HTMLInputElement>()
  };

  const dispatch = useDispatch();

  const handleBlur: EventHandler<any> = e => {
    dispatch(setField({ name: e.target.name, value: e.target.value }));
  };

  return (
    <Wrapper flexDirection="row" flexWrap="wrap">
      <Flex>
        <TextField
          type="text"
          name="musteriAdi"
          label="Müşteri Adı"
          margin="normal"
          className="musteri-adi"
          width={520}
          inputRef={headDataRefs.musteriAdi}
          onBlur={handleBlur}
        />
        <TextField
          type="text"
          name="musteriSehir"
          label="Müşteri İl/ilçe"
          margin="normal"
          className="musteri-il"
          width={300}
          inputRef={headDataRefs.musteriSehir}
          onBlur={handleBlur}
        />
      </Flex>

      <Flex>
        <TextField
          type="text"
          name="siparisAciklama"
          label="Sipariş Açıklama"
          margin="normal"
          className="aciklama"
          width={520}
          inputRef={headDataRefs.siparisAciklama}
          onBlur={handleBlur}
        />
        <TextField
          type="text"
          name="malzemeAdi"
          label="Malzeme Adı"
          margin="normal"
          className="malzeme-adi"
          width={300}
          inputRef={headDataRefs.malzemeAdi}
          onBlur={handleBlur}
        />
      </Flex>
    </Wrapper>
  );
};
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// styled

const Wrapper = styled(Flex)`
  #root & {
    background: #f5f5f5;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-width: 1px 0;
    padding: 5px 10px 10px;

    .musteri-adi {
      width: 520px;
      margin-right: 20px;
      margin-top: 5px;
    }

    .musteri-il {
      width: 300px;
      margin-right: 20px;
      margin-top: 5px;
    }

    .malzeme-adi {
      width: 300px;
      margin-right: 20px;
      margin-top: 5px;
    }

    .aciklama {
      width: 520px;
      margin-right: 20px;
      margin-top: 5px;
    }

    .MuiTextField-root {
      width: 100%;
    }
  }
`;
