import ky from "ky";

let backend = "http://aksakal.srvtr.com/";
if (process.env.NODE_ENV === "development") {
  // backend = "http://aksakal.local/";
}

const api = ky.create({ prefixUrl: backend });

export const postData = (data: any) => {
  return api
    .post("ebatlama-sip2mail/post", {
      json: data,
      headers: {
        //"Content-Type": "application/json"
        //Accept: "application/json",
        //"Access-Control-Allow-Origin": "*"
      }
      //mode: "no-cors"
    })
    .json();
};

export default api;
