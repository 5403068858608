import { createSlice, PayloadAction } from "redux-starter-kit";

export type HeadDataState = {
  musteriAdi: string;
  musteriSehir: string;
  siparisAciklama: string;
  malzemeAdi: string;
};

const initialState: HeadDataState = {
  musteriAdi: "",
  musteriSehir: "",
  siparisAciklama: "",
  malzemeAdi: ""
};

type SetFieldAction = PayloadAction<{
  name: keyof HeadDataState;
  value: string;
}>;

const headData = createSlice({
  slice: "headData",
  initialState,
  reducers: {
    setField(state, action: SetFieldAction) {
      state[action.payload.name] = action.payload.value;
    }
  }
});

export const { setField } = headData.actions;

export default headData.reducer;
