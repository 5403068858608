import React, {
  useEffect,
  KeyboardEvent,
  ChangeEvent,
  EventHandler,
  useCallback
} from "react";
import { Box, Flex } from "@zedflow-mono/styled-system";
import styled from "styled-components/macro";

import KenarBandi from "./KenarBandi";
import Kanal from "./Kanal";
import BtnEkle from "./BtnEkle";
import BtnSil from "./BtnSil";
import SatirNo from "./SatirNo";
import TextField from "../../form-components/TextField";
import {
  useFormRefsContext,
  FormRefsContextFormLinesType
} from "../../form-ref-context/FormRefContext";
import { useDispatch } from "react-redux";
import { setLineField, LineType } from "../lines.slice";

type Props = {
  index: number;
  data?: LineType;
  onAdd: (index: number) => void;
  onDelete: (index: number) => void;
};

const kenarBandiKeyCodes = [/*A(1)*/ 65, /*B(2)*/ 66, /*C(3)*/ 67, /*D(4)*/ 68];

const LineElementsSequence: LineElementKeysType[] = [
  "boy",
  "en",
  "adet",
  "pvcBoy1",
  "pvcBoy2",
  "pvcEn1",
  "pvcEn2",
  "kanal",
  "btnAdd"
];

const LineInputElements: LineInputElementKeysType[] = ["boy", "en", "adet"];

const LineKenarBandiElements: LineSelectElementTargetsType[] = [
  "pvcBoy1",
  "pvcBoy2",
  "pvcEn1",
  "pvcEn2"
];

type LineInputElementKeysType = "boy" | "en" | "adet";

type LineSelectElementKeysType =
  | "pvcBoy1"
  | "pvcBoy2"
  | "pvcEn1"
  | "pvcEn2"
  | "kanal";

type LineButtonElementKeysType = "btnAdd";

type LineSelectElementTargetsType =
  | LineSelectElementKeysType
  | "adet"
  | "btnAdd";

type LineElementKeysType =
  | LineSelectElementKeysType
  | LineInputElementKeysType
  | LineButtonElementKeysType;

const Line = (props: Props) => {
  const formRefContext = useFormRefsContext();
  const refs: FormRefsContextFormLinesType = {
    boy: React.createRef<HTMLInputElement>(),
    en: React.createRef<HTMLInputElement>(),
    adet: React.createRef<HTMLInputElement>(),
    pvcBoy1: React.createRef<HTMLSelectElement>(),
    pvcBoy2: React.createRef<HTMLSelectElement>(),
    pvcEn1: React.createRef<HTMLSelectElement>(),
    pvcEn2: React.createRef<HTMLSelectElement>(),
    kanal: React.createRef<HTMLSelectElement>(),
    btnAdd: React.createRef<HTMLButtonElement>()
  };

  useEffect(() => {
    formRefContext.refs.lines[props.index] = refs;
  }, [formRefContext, props.index, refs]);

  const dispatch = useDispatch();

  const handleBlur: EventHandler<any> = useCallback(
    e => {
      dispatch(
        setLineField({
          index: props.index,
          key: e.target.name as keyof LineType,
          value: e.target.value
        })
      );
    },
    [dispatch, props.index]
  );

  const handleAdd = useCallback(() => {
    props.onAdd(props.index + 1);
    setTimeout(() => {
      if (formRefContext.refs.lines[props.index + 1]) {
        const $elm = formRefContext.refs.lines[props.index + 1].boy.current;
        if ($elm) {
          $elm.focus();
        }
      }
    }, 100);
  }, [formRefContext.refs.lines, props]);

  const handleDelete = useCallback(() => {
    props.onDelete(props.index);
  }, [props]);

  const handleOnKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const target: HTMLInputElement = (e.target
        ? e.target
        : e.currentTarget) as HTMLInputElement;

      const name: LineElementKeysType = target.name as LineElementKeysType;

      const { tagName } = target;

      const keyIndex = kenarBandiKeyCodes.indexOf(e.keyCode);
      if (keyIndex > -1) {
        target.value = keyIndex + 1 + "";

        // if (target.onchange) {
        //   // @ts-ignore
        //   target.onchange(e);
        // }
      }

      const currentElementIndex = LineElementsSequence.indexOf(name);

      let leftTarget: LineElementKeysType =
        LineElementsSequence[currentElementIndex - 1];
      let rightTarget: LineElementKeysType =
        LineElementsSequence[currentElementIndex + 1];

      let $elm = null;

      if (
        LineInputElements.indexOf(name as LineInputElementKeysType) > -1 &&
        e.keyCode === 13 &&
        formRefContext.refs.lines[props.index][rightTarget]
      ) {
        $elm = formRefContext.refs.lines[props.index][rightTarget].current;
      } else if (
        tagName === "SELECT" &&
        e.keyCode === 37 &&
        formRefContext.refs.lines[props.index][leftTarget]
      ) {
        $elm = formRefContext.refs.lines[props.index][leftTarget].current;
      } else if (
        ((tagName === "SELECT" && e.keyCode === 39) || e.keyCode === 13) &&
        formRefContext.refs.lines[props.index][rightTarget]
      ) {
        if (name === "kanal") {
          handleAdd();

          return;
        }
        $elm = formRefContext.refs.lines[props.index][rightTarget].current;
      }
      if ($elm) {
        $elm.focus();
      }
    },
    [formRefContext.refs.lines, handleAdd, props.index]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<any>) => {
      const target: HTMLInputElement = (e.target
        ? e.target
        : e.currentTarget) as HTMLInputElement;

      const name: LineElementKeysType = target.name as LineElementKeysType;

      const currentElementIndex = LineElementsSequence.indexOf(name);

      let rightTarget: LineElementKeysType =
        LineElementsSequence[currentElementIndex + 1];

      if (
        LineKenarBandiElements.indexOf(name as LineSelectElementKeysType) >
          -1 &&
        formRefContext.refs.lines[props.index][rightTarget]
      ) {
        const $elm =
          formRefContext.refs.lines[props.index][rightTarget].current;
        if ($elm) {
          setTimeout(() => {
            $elm.focus();
          }, 100);
        }
      } else if (name === "kanal") {
        handleAdd();
      }
    },
    [formRefContext.refs.lines, handleAdd, props.index]
  );

  return (
    <Wrapper display="flex" alignItems="stretch" flexDirection="row">
      <SatirNo width={40} no={props.index + 1} />
      <StyledTextField
        type="number"
        width={116}
        name="boy"
        index={props.index}
        placeholder="Boy"
        inputRef={refs.boy}
        onKeyUp={handleOnKeyUp}
        onBlur={handleBlur}
      />
      <StyledTextField
        type="number"
        width={116}
        name="en"
        index={props.index}
        placeholder="En"
        inputRef={refs.en}
        onKeyUp={handleOnKeyUp}
        onBlur={handleBlur}
      />
      <StyledTextField
        type="number"
        width={86}
        name="adet"
        index={props.index}
        placeholder="Adet"
        inputRef={refs.adet}
        onKeyUp={handleOnKeyUp}
        onBlur={handleBlur}
      />
      <KenarBandi
        width={80}
        yon="boy"
        num={1}
        name="pvcBoy1"
        index={props.index}
        selectRef={refs.pvcBoy1}
        onKeyUp={handleOnKeyUp}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <KenarBandi
        width={80}
        yon="boy"
        num={2}
        name="pvcBoy2"
        index={props.index}
        selectRef={refs.pvcBoy2}
        onKeyUp={handleOnKeyUp}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <KenarBandi
        width={80}
        yon="en"
        num={1}
        name="pvcEn1"
        index={props.index}
        selectRef={refs.pvcEn1}
        onKeyUp={handleOnKeyUp}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <KenarBandi
        width={80}
        yon="en"
        num={2}
        name="pvcEn2"
        index={props.index}
        selectRef={refs.pvcEn2}
        onKeyUp={handleOnKeyUp}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Kanal
        width={92}
        name="kanal"
        index={props.index}
        selectRef={refs.kanal}
        onKeyUp={handleOnKeyUp}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Flex>
        <BtnEkle onClick={handleAdd} buttonRef={refs.btnAdd} />
        <BtnSil onClick={handleDelete} />
      </Flex>
    </Wrapper>
  );
};

export default React.memo(Line);

// @ts-ignore
Line.whyDidYouRender = true;

const Wrapper = styled(Box)``;

const StyledTextField = styled(TextField)`
  #root & {
    padding: 0px 8px 8px;
    background: #fbfbfb;

    .MuiInput-input {
      text-align: right;
      font-size: 19px;
      height: 24px;

      &::placeholder {
        text-align: center;
        font-size: 16px;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
  }
`;
