import React from "react";
import { hot } from "react-hot-loader";
import styled, { ThemeProvider } from "styled-components/macro";
import { Provider as ReduxProvider } from "react-redux";

import store from "./store";
import Paper from "./components/Paper";
import AppBar from "./features/appbar/AppBar";
import theme from "./theme";
import Header from "./features/header/Header";
import HeadData from "./features/head-data/HeadData";
import Lines from "./features/lines/Lines";
import Footer from "./features/footer/Footer";
import SideNote from "./features/side-note/SideNote";
import FormRefContext, {
  formRefsContextDefaultValue
} from "./features/form-ref-context/FormRefContext";

// @ts-ignore
window.theme = theme;

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <FormRefContext.Provider value={formRefsContextDefaultValue}>
          <AppBar />
          <Header />
          <div className="App">
            <FormWrapper>
              <HeadData />
              <Lines />
              <SideNote />
            </FormWrapper>

            <Footer />

            {/* {process.env.NODE_ENV === "development" && <Debug />} */}
          </div>
        </FormRefContext.Provider>
      </ReduxProvider>
    </ThemeProvider>
  );
};

const FormWrapper = styled(Paper)``;
// @ts-ignore
FormWrapper.whyDidYouRender = true;
// @ts-ignore
App.whyDidYouRender = true;

export default hot(module)(App);
