import React from "react";
import styled from "styled-components/macro";
import { Box } from "@zedflow-mono/styled-system";

const FormHeader = () => {
  return (
    <Wrapper display="flex">
      <Box
        className="satir-no"
        display="inline-block"
        width="40px"
        textAlign="center"
      >
        NO
      </Box>
      <Box
        className="boy"
        display="inline-block"
        width="116px"
        textAlign="center"
      >
        BOY
      </Box>
      <Box
        className="en"
        display="inline-block"
        width="116px"
        textAlign="center"
      >
        EN
      </Box>
      <Box
        className="adet"
        display="inline-block"
        width="86px"
        textAlign="center"
      >
        Adet
      </Box>
      <Box
        display="inline-block"
        width="320px"
        textAlign="center"
        className="pvc"
      >
        <Box>PVC</Box>
        <Box>
          <Box className="pvc-boy" display="inline-block" textAlign="center">
            BOY
          </Box>
          <Box className="pvc-en" display="inline-block" textAlign="center">
            En
          </Box>
        </Box>
      </Box>
      <Box
        className="kanal"
        display="inline-block"
        width="92px"
        textAlign="center"
      >
        Kanal
      </Box>
      <div className="controls" />
    </Wrapper>
  );
};

export default React.memo(FormHeader);

// @ts-ignore
FormHeader.whyDidYouRender = true;

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// styled

const Wrapper = styled(Box)`
  #root & {
    .satir-no {
      border-radius: 10px 0 0 0;
      color: #666;
    }
    .kanal {
      border-radius: 0 10px 0 0;
    }
    .pvc {
      border-radius: 8px 8px 0 0;
    }

    .pvc > ${Box}:first-child {
      font-size: 12px;
    }

    .satir-no,
    .boy,
    .en,
    .adet,
    .kanal {
      margin-top: 29px;
      padding-top: 8px;
      background: #fbfbfb;
    }

    .satir-no,
    .boy,
    .en,
    .adet,
    .controls {
      border-bottom: 1px solid #c1c1c1;
    }
    .pvc {
      padding-top: 5px;
      background: #f4f4f4;
      .pvc-boy,
      .pvc-en {
        background: white;
        width: 50%;
        padding: 10px 0 5px;
        margin-top: 5px;
      }
      .pvc-boy {
        background-color: #dbf1ff;
        border-bottom: 1px solid #64a0c5;
      }
      .pvc-en {
        background-color: #e6f9cf;
        border-bottom: 1px solid #8ab752;
      }
    }
    .kanal {
      background-color: #ffebb7;
      border-bottom: 1px solid #d4b259;
    }
    .controls {
      width: 110px;
      display: inline-block;
    }
  }
`;
